<template>
<div class="about">
  <h1>This page is subject to change. LOL </h1>
  <p>This app is a proof of concept developed by F. Fontana and implemented by M.Carbone using Vue3 and Firebase.
  </p>

  <h2>Special thanks</h2>
  <main style="display: flex;">

    <section style="text-align: left;">

      <ul>
        <li><a href="https://savvyapps.com/blog/definitive-guide-building-web-app-vuejs-firebase" target="_blank">Savvyapps Vuegram</a>
          <span> - Inspiration on using Vue2 with Firebase for a quickstart.</span>
        </li>
        <li><a href="https://www.youtube.com/c/programwitherik?sub_confirmation=1" target="_blank">Program with Erik</a>
          <span> - Inspiration on setInterval</span>
        </li>

      </ul>
    </section>
  </main>

</div>
</template>

<style lang="scss" scoped>
.about {
  margin-top: 2rem;
}
</style>
